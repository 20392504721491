import { render, staticRenderFns } from "./DeleteProductPopup.vue?vue&type=template&id=701d232c&scoped=true&"
import script from "./DeleteProductPopup.vue?vue&type=script&lang=ts&"
export * from "./DeleteProductPopup.vue?vue&type=script&lang=ts&"
import style0 from "./DeleteProductPopup.vue?vue&type=style&index=0&id=701d232c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "701d232c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/naveenkumar/Sites/pickl-web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('701d232c')) {
      api.createRecord('701d232c', component.options)
    } else {
      api.reload('701d232c', component.options)
    }
    module.hot.accept("./DeleteProductPopup.vue?vue&type=template&id=701d232c&scoped=true&", function () {
      api.rerender('701d232c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/DeleteProductPopup.vue"
export default component.exports