






















import ProductItem from '@/components/common/ProductItem.vue'
import DeleteProductPopup from '@/components/common/DeleteProductPopup.vue'
import ProductPopup from '@/components/common/ProductPopup.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

@Component({
  components: {
    ProductItem,
    DeleteProductPopup,
    ProductPopup
  }
})
export default class Products extends Vue {
  public query = ''
  public limit = 100
  public offset = 0
  public brandId = 0
  public actionProduct: any = null
  public products: any[] = []
  public totalCount = 0
  public busy: boolean = false
  public product: {name: string, section: string, description:string, images: any[]} = {
    name: '',
    section: '',
    description: '',
    images: []
  }
  public productDetails: object = {}

  getProducts (clear = true) {
    this.busy = true
    this.$store.dispatch('getProductsList', { query: this.query, limit: this.limit, offset: this.offset, brand_id: this.brandId }).then((response) => {
      if (response.options.query === this.query && response.options.limit === this.limit && this.offset === response.options.offset) {
        if (clear) this.products = []
        this.products.push(...response.response.body.data)
        this.totalCount = response.response.body.count
        this.busy = false
      }
    }, (response) => {
      this.busy = false
    })
  }

  loadMore () {
    this.$data.offset += this.$data.limit
    this.getProducts(false)
  }

  @Watch('query')
  onSearch () {
    Vue.common.delay(() => {
      this.offset = 0
      this.getProducts()
    }, 500)()
  }

  created () {
    this.brandId = this.$store.state.userData.brandId
    this.getProducts()
  }

  refresh () {
    this.offset = 0
    this.getProducts()
  }

  editProductDetails (product:object) {
    this.productDetails = product
  }

  get hasLoadMore (): boolean {
    return this.products.length < this.totalCount
  }
}
