<template>
  <div
    :id="popupId"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2>
            <img src="/img/Alerts/plusbig_icon.png"> Product
          </h2>
          <button
            ref="popupCloseButton"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form v-on:submit.prevent="addProduct">
            <div data-vv-scope="add-product">
              <div class="row">
                <div class="col-xs-12 col-sm-6">
                  <input
                    type="text"
                    placeholder="Product Type"
                    name="name"
                    v-model="product.name"
                    v-validate="'required'"
                    maxlength="23"
                    :class="{'input': true, 'is-danger': errors.has('add-product.name') }"
                  >
                </div>
                <div class="col-xs-12 col-sm-6">
                  <select
                    v-model="product.section"
                    name="section"
                    v-validate="'required'"
                    :class="{'input': true, 'is-danger': errors.has('add-product.section') }"
                  >
                    <option disabled selected value>Category</option>
                    <option
                      v-for="section in productSections"
                      :key="section.id"
                      :value="section.name"
                    >{{section.name}}</option>
                  </select>
                </div>
                <div class="col-xs-12 col-sm-12">
                  <textarea
                    maxlength="60"
                    v-model="product.description"
                    placeholder="Special Notes - 60 Characters Max"
                    class="input textarea"
                    rows="2"
                  ></textarea>
                </div>
                <div class="col-xs-12 col-sm-12" style="margin-bottom:20px;">
                  <input type="checkbox" v-model="product.ageRestricted" id="age_restricted" /> <label for="age_restricted" style="margin-top:8px;">Is Product Age Restricted? (i.e. Alcohol)</label>
                </div>
              </div>
              <h4 class="text-center">Add Product Images</h4>
              <div class="row">
                <div class="col-xs-12 croppa-tip"></div>
                <div
                  class="col-xs-12 col-sm-6 col-md-6 text-center"
                  v-for="(cropper, i) in croppers"
                  :key="'product-image-' + i"
                >
                  <div class="upload-remove-container">
                    <croppa
                      v-model="croppers[i]"
                      placeholder="Select Image"
                      initial-size="contain"
                      :placeholder-font-size="25"
                      :show-remove-button="false"
                      :initial-image="initialImages[i]"
                    ></croppa>
                    <div class="text-left mb10" v-if="i < 1">Required</div>
                    <div class="mb10" v-else>&nbsp;</div>

                    <button
                      v-if="croppers[i] && croppers[i].hasImage()"
                      class="upload-remove"
                      type="button"
                      @click="croppers[i].remove()"
                    ></button>
                    <input type="text" v-model="imageNames[i]" placeholder="Title" class="mb30" maxlength="31">
                  </div>
                </div>
              </div>
            </div>
            <input
              type="submit"
              class="btn-lg-green m0"
              :value="editProductFlag?'UPDATE PRODUCT':'ADD PRODUCT'"
              :disabled="busy"
            >
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'ProductPopup',
  props: ['popupId', 'productDetails', 'editProductFlag', 'brandId'],
  data () {
    return {
      busy: false,
      product: {
        name: '',
        section: '',
        description: '',
        images: [],
        brandId: this.brandId,
        ageRestricted: 0
      },
      croppers: new Array(4),
      productImages: new Array(4),
      imageNames: new Array(4),
      limitPerPage: 100,
      offset: 0,
      productSections: [],
      initialImages: new Array(4)

    }
  },
  methods: {

    generateImage (index) {
      let url = this.croppers[index].generateDataUrl()
      if (!url) {
        return
      }
      this.productImages[index] = url
    },

    addProduct () {
      for (var i = 0; i < 4; ++i) this.generateImage(i)
      this.$validator.validateAll('add-product').then((result) => {
        if (result && !this.busy) {
          this.busy = true
          if (this.editProductFlag) {
            this.productImages.forEach((x, index) => {
              if (x && x.length > 0) this.product.images.push({ 'image': x, 'description': this.imageNames[index] })
            })
            this.$store.dispatch('updateProduct', this.product).then(() => {
              this.$emit('updated')
              this.getProductSections()
              this.success()
              this.$notify({ type: 'success', text: 'Product has been updated successfully' })
              this.busy = false
              this.productImages = new Array(4)
              this.product.images = []
            }, (response) => {
              this.$emit('failed')
              for (var key in response.body) {
                this.$notify({ type: 'error', text: response.body[key][0] })
                let field = this.$validator.fields.find({ name: key, scope: 'add-product' })
                if (field) {
                  this.$validator.errors.add({
                    id: field.id,
                    field: key,
                    msg: response.body[key][0],
                    scope: 'add-product'
                  })
                }
              }
              this.busy = false
            })
          } else {
            this.productImages.forEach((x, index) => {
              if (x && x.length > 0) this.product.images.push({ 'image': x, 'description': this.imageNames[index] })
            })
            this.$store.dispatch('addProduct', this.product).then(() => {
              this.$emit('added')
              this.resetForm()
              this.getProductSections()
              this.success()
              this.$notify({ type: 'success', text: 'Product has been added successfully' })
              this.busy = false
            }, (response) => {
              this.$emit('failed')
              for (var key in response.body) {
                this.$notify({ type: 'error', text: response.body[key][0] })
                let field = this.$validator.fields.find({ name: key, scope: 'add-product' })

                if (field) {
                  this.$validator.errors.add({
                    id: field.id,
                    field: key,
                    msg: response.body[key][0],
                    scope: 'add-product'
                  })
                }
              }
              this.busy = false
            })
          }
        } else {
          this.$emit('failed')
        }
      })
    },

    resetForm () {
      this.croppers.forEach((x) => x.remove())
      let croppers = this.croppers
      Object.assign(this.$data, (this.$options.data).apply(this))
      this.croppers = croppers
      this.$nextTick(() => this.$validator.errors.clear('add-product'))
    },

    getProductSections (clear) {
      this.$store.dispatch('getProductSections', { limit: this.limitPerPage, offset: this.offset }).then((response) => {
        this.offset += this.limitPerPage
        this.productSections.push(...response.response.body.data)
        if (this.offset < response.response.body.count) {
          this.getProductSections()
        }
      })
    },
    success () {
      (this.$refs.popupCloseButton).click()
      this.$emit('added')
      this.busy = false
    }
  },
  created () {
    this.getProductSections()
    let ageRestricted = this.$store.state.userData.ageRestricted
    if (typeof ageRestricted === 'undefined') {
      ageRestricted = 0
    }
    this.product.ageRestricted = ageRestricted
  },
  watch: {
    productDetails () {
      this.product.name = this.productDetails.name
      this.product.section = this.productDetails.section
      this.product.description = this.productDetails.description
      this.product.id = this.productDetails.id
      this.product.ageRestricted = this.productDetails.age_restricted
      var i
      this.product.images = []
      this.initialImages = []
      for (i = 0; i < 4; i++) {
        this.croppers[i].refresh()
        this.imageNames[i] = ''
        if (this.editProductFlag && (typeof this.productDetails.images !== 'undefined')) {
          if (typeof this.productDetails.images[i] !== 'undefined') {
            let image = new Image()
            image.setAttribute('crossorigin', 'anonymous')
            image.src = this.productDetails.images[i].image
            this.initialImage = image
            this.initialImages[i] = image
            this.imageNames[i] = this.productDetails.images[i].description
          }
        }
      }
    }
  }

}
</script>
<style scoped>
.row >>> .croppa-container {
  position: relative;
  padding-top: 100%;
  width: 100%;
}

.row >>> .croppa-container canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
}

.btn-negative {
  font-size: 15px;
  font-weight: 400;
  margin-top: 0;
}

select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url("/img/Create Pickl/selectproductDropdown_icon.png");
  background-color: white;
}

option {
  color: black;
  background-color: white;
}
.textarea {
  width: 100%;
}
</style>
