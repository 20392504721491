var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "green-border-box in-padd white-bg" },
    [
      _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.query,
              expression: "query"
            }
          ],
          staticClass: "search-box",
          attrs: { type: "search", placeholder: "Search product" },
          domProps: { value: _vm.query },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.query = $event.target.value
            }
          }
        })
      ]),
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.products, function(product) {
          return _c(
            "div",
            { key: product.id, staticClass: "col-xs-12 col-sm-6 item-list" },
            [
              _c("ProductItem", {
                attrs: { product: product },
                on: {
                  delete: function($event) {
                    _vm.actionProduct = product
                  },
                  editProduct: _vm.editProductDetails
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _c("div", { staticClass: "text-center" }, [
        _vm.hasLoadMore
          ? _c(
              "button",
              {
                staticClass: "btn-positive",
                attrs: { disabled: _vm.busy },
                on: { click: _vm.loadMore }
              },
              [_vm._v("Load more")]
            )
          : _vm._e()
      ]),
      !_vm.busy && (!_vm.products || _vm.products.length == 0)
        ? _c("h3", { staticClass: "text-center mt20" }, [
            _vm._v("\n      No products found.\n    ")
          ])
        : _vm._e(),
      _c("ProductPopup", {
        attrs: {
          brandId: _vm.brandId,
          popupId: "add-product-popup",
          editProductFlag: false
        },
        on: { added: _vm.refresh }
      }),
      _c("ProductPopup", {
        attrs: {
          brandId: _vm.brandId,
          popupId: "edit-product-popup",
          editProductFlag: true,
          productDetails: _vm.productDetails
        },
        on: { updated: _vm.refresh }
      }),
      _c("DeleteProductPopup", {
        attrs: {
          brandId: _vm.brandId,
          popupId: "delete-product-popup",
          product: _vm.actionProduct
        },
        on: { deleted: _vm.refresh }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }