var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._m(0),
            _c("button", {
              ref: "popupCloseButton",
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close"
              }
            })
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.addProduct($event)
                  }
                }
              },
              [
                _c("div", { attrs: { "data-vv-scope": "add-product" } }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-xs-12 col-sm-6" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.product.name,
                            expression: "product.name"
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        class: {
                          input: true,
                          "is-danger": _vm.errors.has("add-product.name")
                        },
                        attrs: {
                          type: "text",
                          placeholder: "Product Type",
                          name: "name",
                          maxlength: "23"
                        },
                        domProps: { value: _vm.product.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.product, "name", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "col-xs-12 col-sm-6" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.product.section,
                              expression: "product.section"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          class: {
                            input: true,
                            "is-danger": _vm.errors.has("add-product.section")
                          },
                          attrs: { name: "section" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.product,
                                "section",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { disabled: "", selected: "", value: "" }
                            },
                            [_vm._v("Category")]
                          ),
                          _vm._l(_vm.productSections, function(section) {
                            return _c(
                              "option",
                              {
                                key: section.id,
                                domProps: { value: section.name }
                              },
                              [_vm._v(_vm._s(section.name))]
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    _c("div", { staticClass: "col-xs-12 col-sm-12" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.product.description,
                            expression: "product.description"
                          }
                        ],
                        staticClass: "input textarea",
                        attrs: {
                          maxlength: "60",
                          placeholder: "Special Notes - 60 Characters Max",
                          rows: "2"
                        },
                        domProps: { value: _vm.product.description },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.product,
                              "description",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12",
                        staticStyle: { "margin-bottom": "20px" }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.product.ageRestricted,
                              expression: "product.ageRestricted"
                            }
                          ],
                          attrs: { type: "checkbox", id: "age_restricted" },
                          domProps: {
                            checked: Array.isArray(_vm.product.ageRestricted)
                              ? _vm._i(_vm.product.ageRestricted, null) > -1
                              : _vm.product.ageRestricted
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.product.ageRestricted,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.product,
                                      "ageRestricted",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.product,
                                      "ageRestricted",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.product, "ageRestricted", $$c)
                              }
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticStyle: { "margin-top": "8px" },
                            attrs: { for: "age_restricted" }
                          },
                          [_vm._v("Is Product Age Restricted? (i.e. Alcohol)")]
                        )
                      ]
                    )
                  ]),
                  _c("h4", { staticClass: "text-center" }, [
                    _vm._v("Add Product Images")
                  ]),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("div", { staticClass: "col-xs-12 croppa-tip" }),
                      _vm._l(_vm.croppers, function(cropper, i) {
                        return _c(
                          "div",
                          {
                            key: "product-image-" + i,
                            staticClass:
                              "col-xs-12 col-sm-6 col-md-6 text-center"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "upload-remove-container" },
                              [
                                _c("croppa", {
                                  attrs: {
                                    placeholder: "Select Image",
                                    "initial-size": "contain",
                                    "placeholder-font-size": 25,
                                    "show-remove-button": false,
                                    "initial-image": _vm.initialImages[i]
                                  },
                                  model: {
                                    value: _vm.croppers[i],
                                    callback: function($$v) {
                                      _vm.$set(_vm.croppers, i, $$v)
                                    },
                                    expression: "croppers[i]"
                                  }
                                }),
                                i < 1
                                  ? _c(
                                      "div",
                                      { staticClass: "text-left mb10" },
                                      [_vm._v("Required")]
                                    )
                                  : _c("div", { staticClass: "mb10" }, [
                                      _vm._v(" ")
                                    ]),
                                _vm.croppers[i] && _vm.croppers[i].hasImage()
                                  ? _c("button", {
                                      staticClass: "upload-remove",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          return _vm.croppers[i].remove()
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.imageNames[i],
                                      expression: "imageNames[i]"
                                    }
                                  ],
                                  staticClass: "mb30",
                                  attrs: {
                                    type: "text",
                                    placeholder: "Title",
                                    maxlength: "31"
                                  },
                                  domProps: { value: _vm.imageNames[i] },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.imageNames,
                                        i,
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ]),
                _c("input", {
                  staticClass: "btn-lg-green m0",
                  attrs: { type: "submit", disabled: _vm.busy },
                  domProps: {
                    value: _vm.editProductFlag
                      ? "UPDATE PRODUCT"
                      : "ADD PRODUCT"
                  }
                })
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("img", { attrs: { src: "/img/Alerts/plusbig_icon.png" } }),
      _vm._v(" Product\n        ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }