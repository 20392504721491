var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "white-bg green-border-box outer-box" }, [
    _c("div", { staticClass: "inner-box" }, [
      _c("div", { staticClass: "grey-logo-circle" }, [
        _c("img", {
          staticClass: "img-in-circle",
          attrs: {
            src:
              _vm.product.image + "?w=150" ||
              _vm.$common.getDefaultImage("product")
          },
          on: {
            error: function($event) {
              _vm.product.logo = _vm.$common.getDefaultImage("product")
            }
          }
        })
      ]),
      _c("span", { staticClass: "title", attrs: { title: _vm.product.name } }, [
        _vm._v(_vm._s(_vm.product.name))
      ]),
      _c("br"),
      _c(
        "span",
        { staticClass: "description", attrs: { title: _vm.product.section } },
        [_vm._v(_vm._s(_vm.product.section))]
      ),
      _c("br"),
      _c("div", { staticClass: "text-left float-left" }, [
        _c(
          "button",
          {
            staticClass: "btn-negative",
            attrs: {
              "data-target": "#delete-product-popup",
              "data-toggle": "modal",
              type: "button"
            },
            on: {
              click: function($event) {
                return _vm.$emit("delete", _vm.product)
              }
            }
          },
          [_vm._v("DELETE")]
        )
      ]),
      _c("div", { staticClass: "text-right" }, [
        _c(
          "button",
          {
            staticClass: "btn-positive",
            attrs: {
              "data-target": "#edit-product-popup",
              "data-toggle": "modal",
              type: "button"
            },
            on: {
              click: function($event) {
                return _vm.$emit("editProduct", _vm.product)
              }
            }
          },
          [_vm._v("EDIT")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }