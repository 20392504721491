










































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class DeleteProductPopup extends Vue {
  @Prop() popupId!: string;
  @Prop() product!: any;
  @Prop() brandId!: number

  deleteProduct () {
    let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
    this.$store.dispatch('deleteProduct', { brandId: this.brandId, productId: this.product.id }).then(() => {
      this.$emit('deleted')
      popupCloseButton.click()
    }, () => popupCloseButton.click())
  }
}
