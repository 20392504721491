


















import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class ProductItem extends Vue {
    @Prop() product!: any
}
